<script setup lang="ts">
  import { useChatStore } from '@src/store/chat';

  const chatStore = useChatStore();
</script>

<template>
  <div v-if="chatStore.showUserStatusTooltip" class="bg-white px-2 py-1 shadow-md dark:bg-surface-950 dark:border-transparent dark:shadow-none max-w-64 rounded-md">
    <div class="text-xs">{{ chatStore.userStatusTooltipMessage }}</div>
  </div>
</template>
