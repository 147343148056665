// src/services/heartbeat-service.ts
import { useRuntimeConfigurationStore } from '@src/store/runtimeconfiguration';
import { Router } from 'vue-router';

class HeartbeatService {
  private static instance: HeartbeatService;
  private heartbeatWorker: Worker;
  private router: Router;

  private constructor(router: Router) {
    this.heartbeatWorker = new Worker(new URL('@src/workers/heartbeat-worker.ts', import.meta.url), { type: 'module' });
    this.router = router;
    this.setupHeartbeatWorker();
  }

  public static getInstance(router: Router): HeartbeatService {
    if (!HeartbeatService.instance) {
      HeartbeatService.instance = new HeartbeatService(router);
    }
    return HeartbeatService.instance;
  }

  private setupHeartbeatWorker(): void {
    this.heartbeatWorker.addEventListener('message', (_e: any) => {
      const runtimeStore = useRuntimeConfigurationStore();

      if (!runtimeStore.isReadyToRun && this.router.currentRoute.value.name !== 'root') {
        this.router.push({ name: 'root' }).catch(() => {});
      }
    });
  }

  public terminateWorker(): void {
    this.heartbeatWorker.terminate();
  }
}

export default HeartbeatService;
