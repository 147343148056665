<script lang="ts">
  import { useChatStore } from '@src/store/chat';
  import { ref, watch } from 'vue';

  export default {
    props: {
      items: {
        type: Array as () => { id: string; name: string; profileUrl: string }[],
        required: true,
      },

      command: {
        type: Function,
        required: true,
      },
    },

    setup(props) {
      const selectedIndex = ref(0);
      const chatStore = useChatStore();

      watch(props.items, () => {
        selectedIndex.value = 0;
      });

      const setIndex = (index: number) => {
        selectedIndex.value = index;
      };

      const selectItem = (index: number) => {
        const item = props.items[index];

        if (item) {
          props.command({
            id: {
              id: item.id,
              name: item.name,
            },
          });
        }
      };

      const onKeyDown = ({ event }: { event: any }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter' || event.key === 'Tab') {
          enterHandler();
          return true;
        }
        return false;
      };

      const upHandler = () => {
        selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length;
      };

      const downHandler = () => {
        selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
      };

      const enterHandler = () => {
        selectItem(selectedIndex.value);
      };

      return {
        selectedIndex,
        setIndex,
        selectItem,
        upHandler,
        downHandler,
        enterHandler,
        onKeyDown,
        chatStore,
      };
    },
  };
</script>

<template>
  <div v-if="items.length" class="w-full rounded border bg-white p-2 shadow dark:bg-surface-800 dark:border-black">
    <div class="p-1 text-xs font-bold uppercase text-surface-500 dark:text-surface-400">Participants</div>
    <button v-for="(item, index) in items" :key="index" :class="[index === selectedIndex ? 'dark:bg-surface-700 bg-surface-100' : '']" class="flex w-full items-center gap-2 rounded p-2" @mouseenter="setIndex(index)" @click="selectItem(index)">
      <div class="relative">
        <div :class="[chatStore.onlineUserIds.has(item.id) ? 'bg-green-500' : 'bg-surface-500']" class="border-2 size-3 rounded-full absolute bottom-0 right-0"></div>
        <img v-if="item.profileUrl" :src="item.profileUrl" alt="avatar" class="rounded-full size-7" />
        <img
          v-else
          alt="avatar"
          class="rounded-full size-7"
          src="data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%2330308F'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E"
        />
      </div>
      <div class="text-sm text-surface-700 dark:text-surface-200">
        {{ item.name }}
      </div>
    </button>
  </div>
</template>
