<script setup lang="ts">
  import { invoke } from '@tauri-apps/api/core';
  import { UnlistenFn } from '@tauri-apps/api/event';
  import { readFile, stat } from '@tauri-apps/plugin-fs';
  import { DragDropEvent, getCurrentWebview } from '@tauri-apps/api/webview';
  import { sep } from '@tauri-apps/api/path';
  import { Logger } from '@utils/logger';
  import * as Sentry from '@sentry/vue';
  import { CameraIcon, DocumentArrowUpIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
  import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
  import { useAppStore } from '@src/store/app';
  import Dialog from 'primevue/dialog';
  import { Editor } from '@tiptap/vue-3';
  import { useToast } from 'primevue/usetoast';
  import { useRouter } from 'vue-router';
  import { mimeTypes } from '@components/Chat/mimetypes';
  import { getImageSizeFromBlob } from '@utils/chat/media';
  import { useChatStore } from '@src/store/chat';
  import { v4 as uuidv4 } from 'uuid';
  import { withCatch } from '@utils/await-safe';
  import { getCurrentWindow } from '@tauri-apps/api/window';
  import ChatChannel from '@components/Chat/ChatChannel.vue';
  import ChannelSidebar from '@components/Chat/ChannelSidebar.vue';
  import ChatChannelList from '@components/Chat/ChatChannelList.vue';
  import UpdateGroupChat from '@components/Chat/UpdateGroupChat.vue';
  import MessageComposer from '@components/Chat/MessageComposer.vue';
  import CreateGroupChat from '@components/Chat/CreateGroupChat.vue';
  import ChatChannelHeader from '@components/Chat/ChatChannelHeader.vue';
  import ChatTypingIndicator from '@components/Chat/ChatTypingIndicator.vue';
  import { Channel, User as ChatUser } from '@pubnub/chat';
  import { PendingMyTimeInChatMessage } from '@src/types/ChatAndMessaging';
  import EmojiPicker from '@components/EmojiPicker/EmojiPicker.vue';
  import ChatParticipantCard from '@components/Chat/ChatParticipantCard.vue';
  import EmojiReactionCard from '@components/Chat/EmojiReactionCard.vue';
  import MessageOptionTooltip from '@components/Chat/MessageOptionTooltip.vue';
  import { ZstdService as ZSTD } from '@src/services/zstd-service';
  import UserStatus from '@components/Chat/UserStatus.vue';
  import MessageContextMenu from '@components/Chat/MessageContextMenu.vue';
  import ChatService from '@src/services/chat-service';

  let zstd: ZSTD;
  const creatingChannel = ref(false);
  const log = new Logger('CHAT_VIEW');
  const chatService = ChatService.getInstance();

  const appStore = useAppStore();
  const chatStore = useChatStore();
  const currentUserCanModerateChannels = new Set<string>();

  const showCreateChannelDialog = ref(false);
  const editorRef = ref<Editor | null>(null);
  const showAdduserDialog = ref(false);
  const channelSidebarOpen = ref(true);

  const toast = useToast();
  const router = useRouter();

  // for uploading files
  const fileObserverListener = ref<UnlistenFn>();
  const dragging = ref(false);
  const showFileError = ref(false);
  const chatStoreSubscription = ref<() => void>();
  const toManyAttachments = ref(false);
  const windowFocusUnlisten = ref<() => void>();

  const processMessageEdit = async (messageData: PendingMyTimeInChatMessage) => {
    const messageToUpdate = chatStore.currentlyEditingMessage;
    if (!messageToUpdate) {
      return;
    }

    const [error, message] = await withCatch(messageToUpdate.editText(messageData.message));

    if (!error) {
      updateChatStoreMessage(messageData.url, message);
      chatStore.stopEditingCurrentMessage();
      return;
    }

    log.info('Looking for duplicate actions');
    const actionTimeTokens = findDuplicationActions(messageToUpdate, messageData.message);

    if (actionTimeTokens.length === 0) {
      return;
    }

    log.info('Pruning actions');
    await chatService.pruneEditMessageActions(messageToUpdate.timetoken, actionTimeTokens);

    log.info('Retrying message update');
    const [retryError, retriedMessage] = await withCatch(messageToUpdate.editText(messageData.message));

    if (!retryError) {
      updateChatStoreMessage(messageData.url, retriedMessage);
      chatStore.stopEditingCurrentMessage();
    } else {
      log.error(retryError);
    }
  };

  // Helper function to update the message in chatStore
  const updateChatStoreMessage = (url: string, message: any) => {
    const messages = chatStore.channelMessages.get(url);
    if (messages) {
      const index = messages.findIndex((m) => m.timetoken === message.timetoken && m.userId === message.userId);
      if (index > -1) {
        messages[index] = message;
      }
    }
  };

  // Helper function to find duplicate tokens
  const findDuplicationActions = (messageToUpdate: any, message: string): string[] => {
    const tokens: string[] = [];
    if (messageToUpdate.actions?.edited) {
      console.log(messageToUpdate.actions.edited);
      Object.entries(messageToUpdate.actions.edited as Record<string, { uuid: string; actionTimetoken: string }[]>).forEach(([key, value]) => {
        log.info(key);
        if (key === message) {
          value.forEach((action) => {
            log.info(`Found duplicate action: ${action.actionTimetoken}`);
            tokens.push(action.actionTimetoken as string);
          });
        }
      });
    }
    return tokens;
  };

  /**
   * Works with the composer and builds messages to send into a specific channel
   *
   * @param event
   */
  const sendMessageToChannel = async (event: { outgoingMessage: PendingMyTimeInChatMessage; commands: { mentionEveryone: false } }) => {
    const messageData = event.outgoingMessage;
    const mentionEveryone = event.commands?.mentionEveryone ?? false;

    const channel = findChannelViaUrl(messageData.url);
    if (!channel) {
      throw new Error('Channel not found');
    }

    // If editing a message, handle with the edit process
    if (chatStore.currentlyEditingMessage) {
      return processMessageEdit(messageData);
    }

    // If replying to a specific user, add mention
    if (chatStore.currentlyReplyingTo?.userId) {
      const targetUserId = chatStore.currentlyReplyingTo.userId;
      const replyToUser = chatStore.knownUsersByUuid.get(targetUserId);
      if (replyToUser) {
        messageData.mentions = [{ id: replyToUser.id, name: replyToUser.name }];
      }
    }

    const availableFileAttachments = chatStore.channelAttachments.get(channel.id) ?? [];
    const files = availableFileAttachments.map((file) => file.file) ?? [];
    const dimensions = availableFileAttachments.map((file) => file.dimensions);
    const size = availableFileAttachments.map((file) => file.file?.size);

    let uniqueTimetokenForPendingMessage = 0;
    if (files.length > 0) {
      uniqueTimetokenForPendingMessage = chatStore.addPendingSend(channel.id, messageData);
    }

    chatStore.channelAttachments.set(channel.id, []);

    // Reconnect to the channel if needed
    if (!chatService.isCurrentlyConnectedTo(channel.id)) {
      log.warn('Channel missing, reconnecting...');
      await chatService.connectToChannel(channel);
      return;
    }

    const myTimeInFiles = [];
    //TODO Handle Video Thumbnails so the receiver doesn't have to encode a PNG for their preview image.
    // Upload files if present
    if (files.length) {
      const [fileUploadBatchError, batchInfo] = await withCatch(
        appStore.api.startChatFileUploadBatch(
          channel.id,
          files.map((file) => file.name)
        )
      );

      if (fileUploadBatchError || !batchInfo) {
        log.error('Failed to upload batch data.');
        log.error(fileUploadBatchError);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Upload failed, aborting operation', life: 1000 });
        chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
        return;
      }

      const batch_id = batchInfo.data.batch_id;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const [s3UploadError] = await withCatch(appStore.api.uploadMultipartFileToS3(batchInfo.data.upload_links[index].upload_key, file));
        if (s3UploadError) {
          log.error('Failed to upload file');
          log.error(s3UploadError);
          continue;
        }
        log.info(`Uploaded file ${file.name}`);
        chatStore.updatePendingSendCustomStatus(channel.id, uniqueTimetokenForPendingMessage, `Uploaded ${index + 1} of ${files.length} files`);
      }

      const [finishUploadError, successfulUploads] = await withCatch(appStore.api.finishUploadBatch(batch_id));

      if (finishUploadError || !successfulUploads) {
        log.error('Failed to finish batch upload.');
        log.error(finishUploadError);

        toast.add({ severity: 'error', summary: 'Error', detail: 'Upload failed, aborting operation', life: 1000 });
        chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);

        if (batch_id) {
          const [abandonError] = await withCatch(appStore.api.abandonBatch(batch_id));
          if (abandonError) {
            log.error(`Failed to abandon upload batch id: ${batch_id}`);
            log.error(abandonError);
          }
        }
        return;
      }

      myTimeInFiles.push(
        ...successfulUploads.data.map(({ id, file_name, mime_type, file_size, key }) => ({
          id,
          name: file_name,
          type: mime_type,
          size: file_size,
          key,
        }))
      );

      log.info('All files uploaded, batch finalized');
    }

    const params = {
      meta: {
        messageSchema: messageData.messageSchema,
        mentionEveryone: mentionEveryone,
        customType: files.length ? 'file' : messageData.customType,
        dimensions,
        size,
        parentId: messageData.parentId ?? null,
        mytimeinFiles: myTimeInFiles,
      },
      files: [],
      mentionedUsers: messageData.mentions,
    };

    const [sendTextError] = await withCatch(channel.sendText(messageData.message, params));

    if (sendTextError) {
      log.error('Failed to send message');
      log.error(sendTextError);
      if (sendTextError.status === 403) {
        chatService.refreshChatToken();
      }
      if (uniqueTimetokenForPendingMessage) {
        chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
      }
      return;
    }

    chatStore.channelAttachments.set(channel.id, []);
    if (uniqueTimetokenForPendingMessage) {
      chatStore.decrementPendingSend(channel.id, uniqueTimetokenForPendingMessage);
    }
  };

  const registerFocusListener = async () => {
    if (windowFocusUnlisten.value) {
      windowFocusUnlisten.value();
    }

    windowFocusUnlisten.value = await getCurrentWindow().onFocusChanged(async ({ payload: focused }) => {
      log.info('Window focus changed, focused? ' + focused + ', current channel url: ' + chatStore.currentChannelUrl);
      if (!focused) {
        return;
      }

      if (router.currentRoute.value.name !== 'chat') {
        log.info('Not in chat view, not updating particpants');
        return;
      }

      const channel = findChannelViaUrl(chatStore.currentChannelUrl);

      if (!channel) {
        return;
      }

      await chatService.forceLoadCurrentParticipants();
    });
  };

  /**
   * This initializes the chat SDK and configures some listeners to help the composer and other components
   */
  onMounted(async () => {
    await registerFocusListener();
    zstd = await ZSTD.getInstance();
    if (!appStore.currentUser?.id) {
      return;
    }
    await chatService.initializeChat();
    // in rare cases, this sometimes appears to mount twice, so we need to ensure we don't double up on listeners
    if (fileObserverListener.value) {
      log.info('File observer being removed');
      fileObserverListener.value();
      fileObserverListener.value = undefined;
    }
    installFileObserver();
    chatStoreSubscription.value = chatStore.$onAction(({ name, after }) => {
      if (name === 'startEditingMessage') {
        log.info('ok to start editing message');
        after(() => {
          if (!chatStore.currentlyEditingMessage) {
            return;
          }

          if (!chatStore.currentlyEditingMessage.meta?.messageSchema) {
            log.error('No message schema found');
            chatStore.stopEditingCurrentMessage();
            return;
          }

          let numberArray: number[] = JSON.parse(chatStore.currentlyEditingMessage.meta.messageSchema);
          let schemaBuffer: ArrayBuffer = zstd.decompress(new Uint8Array(numberArray));
          let decoder = new TextDecoder();
          let decodedBuffer = decoder.decode(schemaBuffer);
          let messageSchema = JSON.parse(decodedBuffer);

          if (chatStore.currentlyEditingMessage && editorRef.value) {
            editorRef.value.commands.clearContent();
            editorRef.value.commands.setContent(messageSchema);
            editorRef.value.commands.focus();
          }
        });
      } else if (name === 'replyToMessage') {
        after(() => {
          if (editorRef.value) {
            editorRef.value.commands.setContent('');
            editorRef.value.commands.focus();
          }
        });
      }
    });
  });

  onUnmounted(() => {
    if (windowFocusUnlisten.value) {
      windowFocusUnlisten.value();
    }
  });

  /**
   * Locate either a group or an open channel and return as a BaseChannel
   *
   * @param id
   */
  const findChannelViaUrl = (id: string): undefined | Channel => {
    const channel = chatStore.knownChannels.get(id);
    if (!channel) {
      return;
    }
    // @ts-ignore
    return channel;
  };

  const createChannel = async (participants: ChatUser[]): Promise<void | string> => {
    if (creatingChannel.value) {
      return;
    }

    creatingChannel.value = true;
    let [createError, channel_id] = await withCatch(chatService.createChannel(participants));
    if (createError) {
      log.error(createError);
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create channel', life: 5000 });
      creatingChannel.value = false;
      return;
    }

    log.debug('Channel created successfully');

    showCreateChannelDialog.value = false;
    creatingChannel.value = false;

    return channel_id;
  };

  const favorite = (url: string) => {
    appStore.addFavoriteChannel(url);
  };

  const unfavorite = (url: string) => {
    appStore.removeFavoriteChannel(url);
  };

  const setEditorRef = (editor: Editor) => {
    // @ts-ignore
    editorRef.value = editor;
  };

  const installFileObserver = () => {
    // Attach a drag-and-drop event listener to the current webview
    getCurrentWebview()
      .onDragDropEvent(async ({ payload }: { payload: DragDropEvent }) => {
        // Exit early if not on the chat page
        if (router.currentRoute.value.name !== 'chat') {
          return;
        }

        // Exit if no channel is selected, disallowing drop events
        if (!chatStore.currentChannelUrl) {
          return;
        }

        const { type } = payload;
        let files: string[] = [];

        switch (type) {
          case 'enter':
            // Extract filenames from paths
            files = payload.paths?.map((path) => path.split(sep()).pop() as string);

            // Set dragging to true if there are files, avoiding issues with empty paths (common on Windows)
            if (files && files.length > 0) {
              dragging.value = true;
            }
            break;

          case 'drop':
            dragging.value = false;
            if (payload.paths) {
              // Add files to attachments
              //TODO PUT THIS IN A WORKER MAIN THREAD HUNG
              await addFileToAttachments(payload.paths);

              // Focus the editor and reset dragging if there are no excessive attachments
              if (!toManyAttachments.value) {
                editorRef.value?.commands.focus();
              }
            }
            break;

          case 'leave':
            // Reset dragging on leave event
            dragging.value = false;
            break;
        }
      })
      .then((listener) => {
        // Store the listener for potential cleanup
        fileObserverListener.value = listener;
      });
  };

  const getDataPreview = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };
      reader.readAsDataURL(file);
    });
  };

  const addFileToAttachments = async (paths: string[]) => {
    const channel = findChannelViaUrl(chatStore.currentChannelUrl);
    if (!channel) {
      return;
    }

    let attachments = chatStore.channelAttachments.get(chatStore.currentChannelUrl) ?? [];

    if (attachments.length + paths.length > 10) {
      toManyAttachments.value = true;
      showFileError.value = true;
      return;
    }

    for (const index in paths) {
      //get last part of path
      let filename = paths[index].split(sep()).pop() as string;
      let fileDetails: FileUploadDetails = {
        id: uuidv4(),
        pending: true,
        file: new File([], filename),
        extension: '',
        path: paths[index],
      };
      attachments.push(fileDetails);
      chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);
      attachments = chatStore.channelAttachments.get(chatStore.currentChannelUrl) ?? [];
    }

    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].pending) {
        try {
          let updatedFileDetails = await getFileData(attachments[i].path as string, attachments[i].id);
          Object.assign(attachments[i], {
            file: updatedFileDetails.file,
            extension: updatedFileDetails.extension,
            dimensions: updatedFileDetails.dimensions,
            preview: updatedFileDetails.preview,
            videoThumbnail: updatedFileDetails.videoThumbnail,
            pending: false,
          });
          chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);
        } catch (error: any) {
          log.error(error);
          //delete the attachment
          attachments = attachments.filter((attachment) => attachment.id !== attachments[i].id);
          chatStore.channelAttachments.set(chatStore.currentChannelUrl, attachments);

          if (error.message && error.filePath) {
            toast.add({
              severity: 'error',
              summary: 'File too large',
              detail: `${error.message} ${error.filePath}`,
              life: 5000,
            });
          } else {
            toast.add({
              severity: 'error',
              summary: 'Error',
              detail: JSON.stringify(error),
              life: 5000,
            });
          }
        }
      }
    }
  };

  const compressImage = async (path: string, quality: number = 0.7): Promise<string> => {
    let compressedPath = await invoke<string>('compress_image', {
      inputPath: path,
      quality,
    });
    log.debug(`[chat_file] compressed path: ${compressedPath}`);
    return compressedPath;
  };

  const getVideoDimensions = (objectUrl: string): Promise<{ width: number; height: number; originalWidth: number; originalHeight: number }> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');

      video.addEventListener('loadedmetadata', () => {
        const width = video.videoWidth;
        const height = video.videoHeight;

        // Scaling logic to fit within max dimensions
        let scaledWidth, scaledHeight;
        const MAX_WIDTH = 350;
        const MAX_HEIGHT = 500;
        const aspectRatio = video.videoWidth / video.videoHeight;

        if (video.videoWidth > video.videoHeight) {
          scaledWidth = Math.min(MAX_WIDTH, video.videoWidth);
          scaledHeight = Math.round(scaledWidth / aspectRatio);
        } else {
          scaledHeight = Math.min(MAX_HEIGHT, video.videoHeight);
          scaledWidth = Math.round(scaledHeight * aspectRatio);
        }

        // an up the object URL
        resolve({ width: scaledWidth, height: scaledHeight, originalWidth: width, originalHeight: height });
      });

      video.addEventListener('error', (videoError) => {
        log.error(videoError);
        URL.revokeObjectURL(objectUrl); // Clean up on error
        reject(new Error('Failed to load video metadata.'));
      });

      video.src = objectUrl;
    });
  };

  const getFileData = async (path: string, id: string): Promise<FileUploadDetails> => {
    const fileExtension = path.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      return { id, file: new File([], ''), extension: '', path: '' };
    }

    const mimeType: string = mimeTypes.find((mimeType) => mimeType.extension === fileExtension)?.mimeType ?? '';

    const fileStatsPromise = stat(path);
    const sizeInMb = 50;
    const maxSize = sizeInMb * 1024 * 1024;
    log.debug(mimeType);

    //Video Files
    if (mimeType.startsWith('video')) {
      const fileStats = await fileStatsPromise;
      if (fileStats.size >= maxSize) {
        throw { message: `File size exceeds ${sizeInMb}MB`, filePath: path };
      }

      // let length = 0;
      //
      // if (fileStats.size >= 1024 * 1024 * 3) {
      //   length = Math.ceil(fileStats.size * 0.1); //10%
      // } else {
      //   length = fileStats.size;
      // }

      const binaryData = await readFile(path);
      const blob = new Blob([new Uint8Array(binaryData)], { type: mimeType });
      const videoURL = URL.createObjectURL(blob);

      let file = new File([binaryData], path.split(sep()).pop() as string, {
        type: mimeType,
      });

      let dimensions = await getVideoDimensions(videoURL);

      return {
        id,
        file,
        extension: fileExtension,
        path,
        dimensions: dimensions,
        preview: videoURL, //this is only used for the client not uploading
      };
    }
    //Image files
    if (mimeType.startsWith('image')) {
      const fileStats = await fileStatsPromise;
      if (fileStats.size >= maxSize) {
        throw new Error(`File size exceeds ${sizeInMb}MB`);
      }

      const sizeInKilobytes = fileStats.size / 1024;

      // Compress if needed
      if (sizeInKilobytes > 1000 && mimeType.includes('image') && fileExtension.match(/(jpg|jpeg|png)/)) {
        log.debug(`[chat_file] before file size (kb): ${sizeInKilobytes}`);
        path = await compressImage(path);
      }

      const data = await readFile(path); // Read once after potential compression
      let file = new File([data], path.split(sep()).pop() as string, {
        type: mimeType,
      });
      log.debug('created file');
      let dimensions = await getImageSizeFromBlob(new Blob([data]));
      log.debug('got dimensions');
      let previewDataUrl = await getDataPreview(file);
      log.debug('got preview');

      return {
        id,
        file,
        extension: fileExtension,
        path,
        dimensions,
        preview: previewDataUrl,
      };
    }
    //All other files
    else {
      const data: Uint8Array = await readFile(path);
      let file = new File([data], path.split(sep()).pop() as string, {
        type: mimeTypes.find((mimeType) => mimeType.extension === fileExtension)?.mimeType,
      });
      return {
        id,
        file,
        extension: fileExtension,
        path,
      };
    }
  };

  const updateChannel = ({ participants }: { participants: ChatUser[]; exposeHistory: boolean }) => {
    chatService.updateChannel(chatStore.channelUrl, participants);
  };

  const favoriteChannelIds = computed(() => new Set(appStore.favoriteChannels.map((fav) => fav.id)));

  /**
   * TODO: OPTIMIZE THIS
   */
  const nonFavoriteGroupChannels = computed((): Channel[] => {
    const knownChannelsArray = Array.from(chatStore.knownChannels);

    return knownChannelsArray
      .filter(([_url, channel]) => !favoriteChannelIds.value.has(channel.id) && channel.custom?.type !== 'team')
      .map(([_url, channel]) => channel as Channel)
      .sort((a, b) => {
        const aIndex = chatStore.globalChannelOrder?.indexOf(a.id);
        const bIndex = chatStore.globalChannelOrder?.indexOf(b.id);

        return aIndex - bIndex;
      });
  });

  function unicodeToEmoji(unicode: string) {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  }

  const favoriteChannelUrls = computed(() => new Set(appStore.favoriteChannels.map((fav) => fav.id)));

  const favoriteChannels = computed((): Channel[] => {
    return Array.from(chatStore.knownChannels)
      .filter(([url]) => favoriteChannelUrls.value.has(url))
      .map(([_url, channel]) => channel as Channel);
  });
  /**
   * This list is a special set of group channels that have a custom type. They are based on the teams
   * in MyTimeIn
   */
  const teamChannels = computed((): Channel[] => {
    return Array.from(chatStore.knownChannels)
      .filter(([_url, channel]) => channel.custom?.type === 'team')
      .map(([_url, channel]) => channel as Channel);
  });

  const toggleCreateDialog = () => {
    chatStore.composerFocusStealOverride = showCreateChannelDialog.value = !showCreateChannelDialog.value;
  };

  const toggleGroupDialog = () => {
    chatStore.composerFocusStealOverride = showAdduserDialog.value = !showAdduserDialog.value;
  };

  const sendMessageFromSidebar = async ({ message, user }: { message: string; user: ChatUser }) => {
    log.info('Sending message from tooltip.');

    let [createChannelError, channel_id] = await withCatch(createChannel([user]));

    if (createChannelError || !channel_id) {
      log.error('Failed to create channel.');
      log.error(createChannelError);
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create channel.', life: 5000 });
      return;
    }

    const encoder = new TextEncoder();
    const unit8Array: Uint8Array = encoder.encode(message);
    let compressed: Uint8Array = zstd.compress(unit8Array);
    let numberArray: number[] = Array.from(compressed);
    let jsonCompressedMessageSchema = JSON.stringify(numberArray);

    let outgoingMessage: PendingMyTimeInChatMessage = {
      message: message,
      messageSchema: jsonCompressedMessageSchema,
      type: 'text',
      mentions: [],
      url: channel_id,
      customType: null,
    };
    await sendMessageToChannel({
      outgoingMessage,
      commands: { mentionEveryone: false },
    });
  };

  const clearToManyFiles = () => {
    toManyAttachments.value = false;
    showFileError.value = false;
  };

  const applyReaction = async (emoji: Emoji) => {
    if (!chatStore.messageContextTimeToken) {
      return;
    }
    const skin_variation_from_base = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skin_variation_from_base && emoji.skin_variations[skin_variation_from_base]) {
      emoji.unified = emoji.skin_variations[skin_variation_from_base].unified;
    }

    chatService.applyReactionToMessage(chatStore.messageContextTimeToken, unicodeToEmoji(emoji.unified)).catch((error) => {
      log.error(error);
    });
  };

  onUnmounted(async () => {
    log.info('resetting chat store');
    chatService.disconnect(); // Disconnect also calls $reset
    editorRef.value?.destroy();
    if (fileObserverListener.value) {
      log.info('file observer being removed');
      fileObserverListener.value();
      fileObserverListener.value = undefined;
    }
    if (chatStoreSubscription.value) {
      chatStoreSubscription.value();
      log.info('store subscription being removed');
      chatStoreSubscription.value = undefined;
    }
  });

  const hideChatParticipantCard = () => {
    chatStore.participantCardUser = null;
    chatStore.participantCardOpen = false;
    chatStore.composerFocusStealOverride = false;
  };

  const handleContextMenuClose = () => {
    chatStore.messageContextMenuMessage = null;
    chatStore.messageContextMenuUser = null;
    chatStore.showMessageContextMenu = false;
    chatStore.composerFocusStealOverride = false;
  };

  const showSidebarBasedOnWindowSize = () => {
    channelSidebarOpen.value = window.innerWidth >= 1200;
  };

  onMounted(() => {
    showSidebarBasedOnWindowSize();

    window.addEventListener('resize', showSidebarBasedOnWindowSize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', showSidebarBasedOnWindowSize);
  });
</script>

<template>
  <div>
    <div id="floating-chat-reference" class="hidden" />
    <div id="chat-layer" class="absolute z-[99]" @contextmenu.stop.prevent>
      <EmojiPicker v-if="chatStore.emojiPickerOpen" id="floating-chat-emoji-picker" @emoji-selected="applyReaction" />
      <ChatParticipantCard v-if="chatStore.participantCardUser" id="participant-card" v-click-away="hideChatParticipantCard" @close="hideChatParticipantCard" @send-message="sendMessageFromSidebar" />
      <MessageContextMenu v-if="chatStore.showMessageContextMenu" id="message-context-menu" v-click-away="handleContextMenuClose" @close="handleContextMenuClose" />
    </div>
    <div id="chat-layer2" class="absolute z-[99]" @contextmenu.stop.prevent>
      <UserStatus v-if="chatStore.showUserStatusTooltip" id="user-status" />
      <MessageOptionTooltip v-if="chatStore.showMessageOptionTooltip" id="message-options-tooltip" />
      <EmojiReactionCard v-if="chatStore.showEmojiReactionCard" id="emoji-reaction-card" />
    </div>

    <CreateGroupChat v-if="showCreateChannelDialog" :visible="showCreateChannelDialog" :loading="creatingChannel" @create="createChannel" @close="toggleCreateDialog()" />
    <UpdateGroupChat v-if="showAdduserDialog" :visible="showAdduserDialog" @update="updateChannel" @close="toggleGroupDialog" />

    <Dialog v-model:visible="showFileError" :dismissable-mask="true" :closeable="true" modal :draggable="false" class="w-[25vw] md:w-[25vw]" :block-scroll="true" @update:visible="clearToManyFiles">
      <template #container>
        <div class="justify-center flex flex-col items-center p-3 text-white last:border-b-0 rounded-lg select-none transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'bg-red-500 animate-shake' : 'bg-primary-500 dark:bg-primary-600']">
          <div class="border-dashed border-surface-200 border-2 w-full text-center h-44 rounded-md">
            <div class="flex justify-center -mt-16 mb-4">
              <DocumentTextIcon class="text-surface-200 size-32 mt-1 -mr-14 -rotate-[22deg] transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
              <DocumentArrowUpIcon class="text-surface-200 size-32 z-10 -mt-2 transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
              <CameraIcon class="text-surface-200 size-32 -ml-14 mt-2 rotate-[22deg] z-8 transition-colors duration-300 ease-in-out" :class="[toManyAttachments ? 'fill-red-500' : 'fill-primary-500 dark:fill-primary-600']" />
            </div>

            <div class="px-4">
              <h1 class="text-2xl mb-1 font-extrabold line-clamp-1 break-all">
                <span v-if="!toManyAttachments"> Upload to {{ chatStore.currentChannel?.name }} </span>
                <span v-else>To many files!</span>
              </h1>
              <p class="text-xs">
                <span v-if="!toManyAttachments">You can add comments before uploading.</span>
                <span v-else>You can upload a maximum of 10 files at once!</span>
              </p>
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <Dialog v-model:visible="dragging" :dismissable-mask="true" :closeable="true" modal :draggable="false" class="w-[25vw] md:w-[25vw]" :block-scroll="true">
      <template #container>
        <div class="justify-center flex flex-col items-center p-3 text-white last:border-b-0 rounded-lg select-none transition-colors duration-300 ease-in-out bg-primary-500 dark:bg-primary-600">
          <div class="border-dashed border-surface-200 border-2 w-full text-center h-44 rounded-md">
            <div class="flex justify-center -mt-16 mb-4">
              <DocumentTextIcon class="text-surface-200 size-32 mt-1 -mr-14 -rotate-[22deg] transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
              <DocumentArrowUpIcon class="text-surface-200 size-32 z-10 -mt-2 transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
              <CameraIcon class="text-surface-200 size-32 -ml-14 mt-2 rotate-[22deg] z-8 transition-colors duration-300 ease-in-out fill-primary-500 dark:fill-primary-600" />
            </div>

            <div class="px-4">
              <h1 class="text-2xl mb-1 font-extrabold line-clamp-1 break-all">Upload to {{ chatStore.currentChannel?.name }}</h1>
              <p class="text-xs">You can add comments before uploading.</p>
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <div class="flex">
      <!-- Channel List -->
      <ChatChannelList
        :team-channels="teamChannels"
        :favorite-channels="favoriteChannels"
        :non-favorite-channels="nonFavoriteGroupChannels"
        :loading="chatStore.initialLoad"
        :channel-alerts="chatStore.channelAlerts"
        :unread-mentions="chatStore.unreadMentions"
        :current-channel-url="chatStore.currentChannelUrl"
        :non-favorite-group-channels="nonFavoriteGroupChannels"
        @create="toggleCreateDialog()"
        @change-channel="chatService.changeChannel"
        @favorite="favorite"
        @unfavorite="unfavorite"
      />
      <!-- Main Area -->
      <section v-if="chatStore.currentChannel" class="grow flex flex-col md:max-h-screen md:min-h-screen overflow-hidden">
        <ChatChannelHeader @add-member="toggleGroupDialog" @open-sidebar="channelSidebarOpen = !channelSidebarOpen" @favorite="favorite" @unfavorite="unfavorite" />
        <!--Chat / Members 2 Column Layout -->
        <div class="flex grow z-50 overflow-hidden">
          <!--Left - Chat Column-->
          <div class="flex-col flex grow overflow-hidden">
            <ChatChannel class="flex grow" :channel="chatStore.currentChannel" :loading="chatStore.initialLoad" :operator-mode="currentUserCanModerateChannels.has(chatStore.channelUrl)" style="height: 1px" @send-message="sendMessageFromSidebar" />
            <!--Message Composer and User is Typing-->
            <div v-if="chatStore.chatUserId && chatStore.currentChannelUrl" class="shrink-0 relative">
              <MessageComposer :channel-url="chatStore.currentChannelUrl" @attach="addFileToAttachments" @send="sendMessageToChannel" @share-editor="setEditorRef" />
              <ChatTypingIndicator class="" />
            </div>
          </div>
          <!--Right - Member List Column-->
          <ChannelSidebar v-if="channelSidebarOpen" class="shrink-0 w-[240px]" :open="channelSidebarOpen" @toggle="channelSidebarOpen = !channelSidebarOpen" @send-message="sendMessageFromSidebar" />
        </div>
      </section>
    </div>
  </div>
</template>
