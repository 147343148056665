<script setup lang="ts">
  import { useRoute } from 'vue-router';
  const route = useRoute();
  const props = defineProps<{
    to: string;
    tooltip?: string;
    id: string;
  }>();
</script>

<template>
  <router-link :id="id" :to="to" class="w-full group py-1.5 duration-300 ease-in" :class="[route.path === props.to ? 'text-primary-500 bg-primary-100/20 dark:bg-primary-50/5' : 'text-surface-700 dark:text-surface-300 hover:bg-primary-200/20 dark:hover:bg-primary-200/20']">
    <div class="flex items-center justify-center">
      <div class="size-6 ease-out duration-200 relative">
        <slot v-if="route.path !== props.to" name="notActive"></slot>
        <slot v-else name="active"></slot>
      </div>
    </div>
    <div class="-mx-4 mt-2 break-words text-center text-xs" :class="[route.path === props.to ? 'dark:text-primary-400' : 'text-black dark:text-white']">
      {{ tooltip }}
    </div>
  </router-link>
</template>
