import { defineStore } from 'pinia';

function getInitialState() {
  return {
    messages: [] as ChatGptMessage[],
    warningAccepted: false as boolean,
  };
}
export const useGptStore = defineStore('gpt', {
  state: () => getInitialState(),
  actions: {},
});
