<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import ChatParticipant from '@components/Chat/ChatParticipant.vue';
  import VirtualScroller from 'primevue/virtualscroller';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';
  import { useRoute } from 'vue-router';
  import Button from 'primevue/button';
  import { useAppStore } from '@src/store/app';
  const route = useRoute();
  const appStore = useAppStore();

  defineProps<{
    open: boolean;
  }>();

  const emit = defineEmits(['toggle', 'send-message']);
  const onChat = ref(route.fullPath === '/chat');

  watch(
    () => route.fullPath,
    (newPath) => {
      if (newPath === '/chat') {
        onChat.value = true;
      } else {
        onChat.value = false;
      }
    }
  );

  const chatStore = useChatStore();
  const selectedUser = ref<KnownChatUser>();
  const clickingChannels = ref(false);
  const clickingIntervalId = ref<ReturnType<typeof setInterval>>();

  const filteredParticipants = computed((): KnownChatUser[] => {
    if (['announcements', 'modcon_2024'].includes(chatStore.currentChannelUrl)) {
      return [];
    }
    if (!chatStore.currentChannelParticipants?.size || !Array.isArray(chatStore.currentChannelUsers) || chatStore.currentChannelUsers.length === 0) {
      return [];
    }

    const operators = chatStore.operators.get(chatStore.currentChannelUrl) ?? [];
    const operatorsAsKnownUsers: KnownChatUser[] = operators ? (operators.map((id) => chatStore.knownUsersByUuid.get(id)).filter(Boolean) as KnownChatUser[]) : [];
    //All the ? Operators because if their chat SDK is not initialized shit gets weird.
    const orderedChannelUsers = (chatStore.currentChannelUsers || [])?.filter((u) => !operatorsAsKnownUsers.includes(u))?.toSorted((a, b) => a.name.localeCompare(b.name)) || [];

    return [...operatorsAsKnownUsers, ...orderedChannelUsers];
  });

  const sendMessage = ({ user, message }: { user: KnownChatUser; message: string }) => {
    selectedUser.value = user;
    emit('send-message', { user, message });
  };

  const clickAllChannels = () => {
    let elements: HTMLButtonElement[] = [];
    filteredParticipants.value.forEach((user: KnownChatUser) => {
      let el = document.getElementById(`participant-card-${user.id}`) as HTMLButtonElement;
      elements.push(el);
    });

    let index = 0; // Start with the first element
    let interval = 100;

    clickingIntervalId.value = setInterval(() => {
      if (elements[index]) {
        elements[index].click(); // Click the current element
        console.log(`Clicked element with ID: ${elements[index].id}`);
      } else {
        console.warn(`Element at index ${index} not found.`);
      }

      index += 1;
      if (index > 10 || index > elements.length) {
        index = 0;
      }
    }, interval); // Return the interval ID in case you want to clear it later
    clickingChannels.value = true;
  };

  const stopClickingAllChannels = () => {
    if (clickingIntervalId.value) {
      clearInterval(clickingIntervalId.value);
      clickingIntervalId.value = undefined;
      clickingChannels.value = false;
    }
  };
</script>

<template>
  <div class="bg-surface-50 dark:bg-surface-800/80 h-full p-2 group" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
    <div v-if="open" class="relative h-full flex flex-col">
      <div class="space-y-2">
        <!--        <h2 class="flex-initial font-bold text-surface-600 dark:text-surface-300 text-2xs uppercase">Description</h2>-->
        <!--        <p v-if="chatStore.currentChannelRaw?.description" class="text-sm text-surface-700 dark:text-surface-300 mb-2">-->
        <!--          {{ chatStore.currentChannelRaw?.description }}-->
        <!--        </p>-->
        <h2 class="flex-initial font-bold text-surface-600 dark:text-surface-300 text-2xs uppercase">Members - {{ chatStore.currentChannelParticipants.size }}</h2>
      </div>
      <!-- DONT REMOVE HEIGHT, ITS THE MAGIC SAUCE -->
      <!-- Look I removed it don't yell at me we changed the layout so this should happen but i left i here cause magic-->
      <!--      <div class="flex-1" style="height: 100px">-->
      <!-- v-if is for inital load so it will draw when it is visible. Key the Virtual Scroller with the length of the participant list with the length of the particpants to force a redraw when the size changes -->
      <div v-if="appStore.isDevUser" class="px-2 pt-1 mb-2">
        <Button v-if="!clickingChannels" class="w-full" severity="danger" outlined @click="clickAllChannels">Click All Users</Button>
        <Button v-else class="w-full" severity="danger" outlined @click="stopClickingAllChannels">Stop Clicking All Users</Button>
      </div>
      <VirtualScroller v-if="onChat" :key="filteredParticipants.length" :items="filteredParticipants" :item-size="50" class="small-scroll grow">
        <template #item="{ item }">
          <ChatParticipant :key="item.id" :user="item" class="mb-1" @send-message="sendMessage" />
        </template>
      </VirtualScroller>

      <!--      </div>-->
    </div>
  </div>
</template>
