import ChatCacheService from '@src/services/chat-cache-service';
import { Channel, Message, TimetokenUtils } from '@pubnub/chat';
import { useAppStore } from '@src/store/app';
import { ChatComposerState, ChatReactionCollection, KnownChatUser, MyTimeInChatMessage, PendingMyTimeInChatMessage } from '@src/types/ChatAndMessaging';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
import { addToArrayInMap, removeFromArrayInMapWithPredicate } from '@utils/map-utils';
import { serializeMap } from '@utils/serializers';
import { defineStore } from 'pinia';
import { wrapPubnubMessageToFriendlyObservable } from '@utils/chat/message-wrapper';

const log = new Logger('STORE:CHAT');

const STATE_REPLYING = 'replying';
const STATE_EDITING = 'editing';

// @ts-ignore
const STATE_COMPOSING = 'composing';
// @ts-ignore
const EVENT_USER_DND = 4;

export const useChatStore = defineStore('chat', {
  state: () => ({
    initialLoad: true,
    chatUserId: '',
    channelUrl: '',

    knownChannels: new Map<string, Channel>(),
    channelNames: new Map<string, string>(),

    onlineUserIds: new Set(),
    idleUserIds: new Set(),
    knownUsersByUuid: new Map<string, KnownChatUser>(),
    allChannelMembers: new Map<string, Set<string>>(),

    unreadMentions: new Map<string, number>(),
    channelAlerts: new Map<string, number>(),
    channelComposerStates: new Map<string, ChatComposerState>(),

    channelMessages: new Map<string, MyTimeInChatMessage[]>(),
    channelAttachments: new Map<string, FileUploadDetails[]>(),
    pinnedMessagesForChannels: new Map<string, MyTimeInChatMessage[]>(),

    mentionsOpen: false,
    composerFocusStealOverride: false,
    largeEditorMode: false,

    currentChannelMutations: 0,

    optimizingUploads: false,

    currentlyTyping: new Set<string>(),
    operators: new Map<string, string[]>(),

    emojiPickerOpen: false,

    participantCardOpen: false,
    participantCardUser: null as KnownChatUser | null,

    showMessageContextMenu: false,
    messageContextMenuUser: null as KnownChatUser | null,
    messageContextMenuMessage: null as MyTimeInChatMessage | null,
    contextMenuImage: null as HTMLImageElement | null,

    showUserStatusTooltip: false,
    userStatusTooltipMessage: null as string | null,

    showMessageOptionTooltip: false,
    messageOptionTooltipMessage: null as string | null,

    reactionEmoji: null as string | null,
    showEmojiReactionCard: false,
    emojiReactionTooltipMessage: null as string | null,

    messageContextTimeToken: null as string | null,

    pendingMessagesMap: new Map<string, PendingMyTimeInChatMessage[]>(),
    knownReactionsToMessages: new Map<string, ChatReactionCollection>(),

    // first draw?
    firstDraw: false,
    fullNetwork: [] as KnownChatUser[],

    globalChannelOrder: [] as string[],
    unreadMessagesPerChannel: new Map<string, number>(),
    lastReadTimestampPerChannel: new Map<string, number>(),

    mutedChannels: new Set<string>(),

    rawMessages: new Map<string, Message>(),
  }),
  actions: {
    replyToMessage(timeToken: string) {
      const messages = this.currentChannelMessages;
      const message = messages.find((m) => m.timetoken === timeToken);
      if (!message) {
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_REPLYING,
        replyingToMessage: message,
        editingMessage: null,
      });
    },
    stopReplyingToMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    startEditingMessage(timeToken: string) {
      const message = this.rawMessages.get(timeToken);
      if (!message) {
        log.error('Message not found. Can not edit null message');
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_EDITING,
        replyingToMessage: null,
        editingMessage: wrapPubnubMessageToFriendlyObservable(message as Message),
      });
    },
    stopEditingCurrentMessage() {
      this.stopEditingMessage(this.channelUrl);
    },
    stopEditingMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    incrementAlerts(channelUrl: string) {
      const currentAlertCount = this.channelAlerts.get(channelUrl) ?? 0;
      this.channelAlerts.set(channelUrl, currentAlertCount + 1);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    addMention(channelUrl: string) {
      const currentMentionCount = this.unreadMentions.get(channelUrl) ?? 0;
      this.unreadMentions.set(channelUrl, currentMentionCount + 1);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    removeAlerts(channelUrl: string) {
      this.channelAlerts.delete(channelUrl);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    removeMentions(channelUrl: string) {
      this.unreadMentions.delete(channelUrl);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    async refreshChatNetwork(): Promise<void> {
      // TODO: REMOVE THIS FUNCTION?
      const appStore = useAppStore();
      const [getChatNetworkError, data] = await withCatch(appStore.api.getChatNetwork());
      if (getChatNetworkError || !data) {
        log.error('Failed to get chat network');
        log.error(getChatNetworkError);
        return;
      }

      const users = data.users;
      this.fullNetwork = users;
      ChatCacheService.getInstance().addUsers(users).catch();
    },
    addPendingSend(channelId: string, pendingMessage: PendingMyTimeInChatMessage): number {
      // perform some additional mutations to make it look like a partial<Message>
      pendingMessage.timetoken = TimetokenUtils.dateToTimetoken(new Date());
      pendingMessage.content = {
        files: [],
        text: 'Sending Message...',
        type: 'text',
        meta: {
          type: 'pending',
        },
      };
      pendingMessage.mentionedUsers = [];
      pendingMessage.channelId = channelId;
      pendingMessage.userId = this.chatUserId;
      pendingMessage.meta = { type: 'pending' };
      pendingMessage.customStatus = 'Sending Message...';
      addToArrayInMap(this.pendingMessagesMap, channelId, pendingMessage);

      return pendingMessage.timetoken;
    },
    updatePendingSendCustomStatus(channelId: string, pendingTimetoken: number, status: string) {
      const pendingMessage = this.pendingMessagesMap.get(channelId)?.find((m) => m.timetoken === `${pendingTimetoken}`);

      if (!pendingMessage || !pendingMessage.content) {
        return;
      }

      pendingMessage.content.text = pendingMessage.customStatus = status;
    },
    decrementPendingSend(channelId: string, pendingTimetoken: number) {
      removeFromArrayInMapWithPredicate(this.pendingMessagesMap, channelId, (m) => m.timetoken === pendingTimetoken);
    },
    closeAllOpenMenus() {
      this.showMessageContextMenu = false;

      this.emojiPickerOpen = false;

      this.participantCardOpen = false;
      this.participantCardUser = null;

      this.composerFocusStealOverride = false;

      const layer1 = document.getElementById('chat-layer');
      const layer2 = document.getElementById('chat-layer');

      if (layer1) {
        layer1.style.visibility = 'hidden';
        layer1.style.top = '0px';
        layer1.style.left = '0px';
      }

      if (layer2) {
        layer2.style.visibility = 'hidden';
        layer2.style.top = '0px';
        layer2.style.left = '0px';
      }
    },
  },
  getters: {
    currentChannel: (state): Channel | undefined => {
      return state.knownChannels.get(state.channelUrl) as Channel | undefined;
    },
    currentChannelName: (state) => {
      return state.channelNames.get(state.channelUrl) ?? state.channelUrl;
    },
    currentlyReplyingTo: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.replyingToMessage ?? null) as Message | null;
    },
    currentlyEditingMessage: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.editingMessage ?? null) as Message | null;
    },
    currentChannelParticipants: (state): Set<string> => {
      return state.allChannelMembers.get(state.channelUrl) ?? new Set<string>();
    },
    currentChannelUsers: (state): KnownChatUser[] => {
      const idsInCurrentChannel = state.allChannelMembers.get(state.channelUrl) ?? new Set<string>();
      return Array.from(idsInCurrentChannel)
        .map((id) => state.knownUsersByUuid.get(id) as KnownChatUser)
        .filter((user): user is KnownChatUser => user !== undefined); // Filters out undefined users
    },
    currentChannelUrl: (state): string => {
      return state.channelUrl;
    },
    currentChannelMessages: (state): MyTimeInChatMessage[] => {
      return (state.channelMessages.get(state.channelUrl) ?? []) as MyTimeInChatMessage[];
    },
    currentPendingMessages: (state): PendingMyTimeInChatMessage[] => {
      return state.pendingMessagesMap.get(state.channelUrl) ?? [];
    },
    currentlyTypingNames: (state): string[] => {
      return Array.from(state.currentlyTyping).map((id) => state.knownUsersByUuid.get(id)?.name ?? '');
    },
    currentChannelRaw: (state): Channel | undefined => {
      return state.knownChannels.get(state.channelUrl) as Channel | undefined;
    },
    currentChannelPins: (state): MyTimeInChatMessage[] => {
      return (state.pinnedMessagesForChannels.get(state.channelUrl) ?? []) as MyTimeInChatMessage[];
    },
    currentChannelPinnedTimetokens: (state): string[] => {
      return state.pinnedMessagesForChannels.get(state.channelUrl)?.map((m) => m.timetoken) ?? [];
    },
    getUser:
      (state) =>
      (id: string): KnownChatUser | undefined => {
        return state.knownUsersByUuid.get(id);
      },
    canOperateOnCurrentChannel: (state): boolean => {
      return state.operators.get(state.channelUrl)?.includes(state.chatUserId ?? '') ?? false;
    },
    isOperatorOnCurrentChannel:
      (state) =>
      (id: string): boolean => {
        return state.operators.get(state.channelUrl)?.includes(id) ?? false;
      },
    getUserStatus:
      (state) =>
      (id: string): string => {
        return state.knownUsersByUuid.get(id)?.statusMessage ?? '';
      },
    reactionsForMessage:
      (state) =>
      (timetoken: string | number): ChatReactionCollection => {
        return state.knownReactionsToMessages.get(`${timetoken}`) ?? {};
      },
  },
});
