<script setup lang="ts">
  import MenuAvatar from '@components/Navigation/MenuAvatar.vue';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import mtiGuy from '@images/mti_guy.png';
  import mtiGuyBright from '@images/mti_guy_bright.png';
  import { useAppStore } from '@src/store/app';
  import { HomeIcon, ChatBubbleLeftRightIcon, SparklesIcon, ClipboardDocumentCheckIcon, CalendarDaysIcon, PresentationChartBarIcon, ArrowsRightLeftIcon, ArrowDownTrayIcon, SwatchIcon, WrenchIcon, InboxStackIcon } from '@heroicons/vue/24/outline';
  import {
    InboxStackIcon as InboxStackIconSolid,
    HomeIcon as HomeIconSolid,
    ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
    SparklesIcon as SparklesIconSolid,
    ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
    CalendarDaysIcon as CalendarDaysIconSolid,
    PresentationChartBarIcon as PresentationChartBarIconSolid,
    SwatchIcon as SwatchIconSolid,
    WrenchIcon as WrenchIconSolid,
  } from '@heroicons/vue/24/solid';
  import { useRuntimeConfigurationStore } from '@src/store/runtimeconfiguration';
  import SidebarIconLink from '@components/Sidebar/SidebarIconLink.vue';
  import ViewSwitch from '@components/Sidebar/ViewSwitch.vue';
  import { useChatStore } from '@src/store/chat';
  import { relaunch } from '@tauri-apps/plugin-process';
  import { Logger } from '@utils/logger';
  import { SentinelKey } from '@utils/vue/symbols';
  import injectStrict from '@utils/vue/utils';

  const log = new Logger('SIDEBAR_NAVIGATION');
  const appState = useAppStore();
  const runtimeConfiguration = useRuntimeConfigurationStore();
  const online = ref(true);
  const sentinel = injectStrict(SentinelKey);

  const chatStore = useChatStore();

  const clearUpdate = () => {
    runtimeConfiguration.newerVersionAvailable = false;
  };

  const installUpdate = () => {
    if (!runtimeConfiguration.newerVersionAvailable) {
      return;
    }
    sentinel.update
      ?.install()
      .then(() => {
        relaunch();
      })
      .catch((error: any) => {
        clearUpdate();
        log.error('Error installing update');
        log.error(error);
      });
  };

  const hasMentions = computed(() => {
    if (Array.from(chatStore.unreadMentions.values()).length > 0) {
      return true;
    }
    return false;
  });

  const mentionCount = computed(() => {
    let alerts = Array.from(chatStore.channelAlerts.values());

    let count = 0;
    for (let alertCount of alerts) {
      if (alertCount) {
        count += alertCount;
      }
    }

    return count;
  });

  onMounted(() => {
    window.addEventListener('online', () => (online.value = true));
    window.addEventListener('offline', () => (online.value = false));
  });

  onUnmounted(() => {
    window.removeEventListener('online', () => (online.value = true));
    window.removeEventListener('offline', () => (online.value = false));
  });
</script>
<template>
  <div class="select-none">
    <div class="flex h-full max-h-screen flex-col items-center overflow-y-auto overflow-x-clip border-r bg-white w-[6.0625rem] dark:border-white/10 dark:bg-surface-800 small-scroll">
      <div class="py-6">
        <router-link to="/dashboard">
          <img :src="mtiGuyBright" alt="MTI Guy" class="hidden object-contain w-[50px] h-[50px] dark:block" />
          <img :src="mtiGuy" alt="MTI Guy" class="block object-contain w-[50px] h-[50px] dark:hidden" />
        </router-link>
      </div>
      <SidebarIconLink id="dashboard" tooltip="Dashboard" to="/dashboard">
        <template #notActive>
          <HomeIcon />
        </template>
        <template #active>
          <HomeIconSolid />
        </template>
      </SidebarIconLink>
      <SidebarIconLink id="requests" tooltip="Requests" to="/requests">
        <template #notActive>
          <CalendarDaysIcon />
        </template>
        <template #active>
          <CalendarDaysIconSolid />
        </template>
      </SidebarIconLink>
      <template v-if="appState.instanceType === 'internal'">
        <SidebarIconLink id="pay_periods" tooltip="Pay Periods" to="/pay">
          <template #notActive>
            <ClipboardDocumentCheckIcon />
          </template>
          <template #active>
            <ClipboardDocumentCheckIconSolid />
          </template>
        </SidebarIconLink>
        <SidebarIconLink id="chat" tooltip="Chat" to="/chat">
          <template #notActive>
            <ChatBubbleLeftRightIcon class="" />
            <div v-if="mentionCount > 0" class="absolute top-2 -right-7">
              <div
                class="inline-flex items-center justify-center rounded-lg font-bold outline outline-1 text-[12px] px-1.5"
                :class="[hasMentions ? 'bg-red-500 outline-red-600 text-white' : 'bg-surface-500 outline-surface-600 dark:bg-surface-100 dark:outline-surface-100 dark:text-black text-white']"
              >
                {{ mentionCount > 100 ? '99+' : mentionCount }}
              </div>
            </div>
          </template>
          <template #active>
            <ChatBubbleLeftRightIconSolid />
            <div v-if="mentionCount > 0" class="absolute top-2 -right-7">
              <div
                class="inline-flex items-center justify-center rounded-lg font-bold outline outline-1 text-[12px] px-1.5"
                :class="[hasMentions ? 'bg-red-500 outline-red-600 text-white' : 'bg-surface-500 outline-surface-600 dark:bg-surface-100 dark:outline-surface-100 dark:text-black text-white']"
              >
                {{ mentionCount > 100 ? '99+' : mentionCount }}
              </div>
            </div>
          </template>
        </SidebarIconLink>
        <SidebarIconLink id="mod_gpt" tooltip="MOD GPT" to="/gpt">
          <template #notActive>
            <SparklesIcon />
          </template>
          <template #active>
            <SparklesIconSolid />
          </template>
        </SidebarIconLink>
        <SidebarIconLink id="prompt_hub" tooltip="Prompt Hub" to="/prompts">
          <template #notActive>
            <InboxStackIcon />
          </template>
          <template #active>
            <InboxStackIconSolid />
          </template>
        </SidebarIconLink>
        <SidebarIconLink id="fine_tuning" tooltip="Fine Tuning" to="/FineTuning">
          <template #notActive>
            <SwatchIcon />
          </template>
          <template #active>
            <SwatchIconSolid />
          </template>
        </SidebarIconLink>
        <SidebarIconLink id="diagnostics" tooltip="Diagnostics" to="/diagnostics">
          <template #notActive>
            <WrenchIcon />
          </template>
          <template #active>
            <WrenchIconSolid />
          </template>
        </SidebarIconLink>
      </template>
      <template v-if="appState.isDevUser">
        <SidebarIconLink id="tooltip" tooltip="Activity" to="/activity">
          <template #notActive>
            <PresentationChartBarIcon />
          </template>
          <template #active>
            <PresentationChartBarIconSolid />
          </template>
        </SidebarIconLink>
      </template>
      <div class="flex grow flex-col justify-end gap-y-4 pb-4 w-full items-center">
        <button v-if="runtimeConfiguration.newerVersionAvailable" v-tooltip="'Update'" class="size-12 rounded-full flex flex-col justify-center p-2 text-green-500 hover:bg-surface-700 dark:hover:bg-surface-900" @click="installUpdate">
          <span class="flex items-center justify-center w-full">
            <ArrowDownTrayIcon class="size-7 stroke-2"></ArrowDownTrayIcon>
          </span>
        </button>
        <template v-if="appState.isDevUser">
          <ViewSwitch tooltip="Swap" @click="appState.swapInstanceType()">
            <ArrowsRightLeftIcon class="rounded text-white" :class="[appState.instanceType === 'internal' ? 'bg-red-500' : 'bg-yellow-500']" />
          </ViewSwitch>
        </template>
        <div class="relative">
          <MenuAvatar />
          <div v-if="online" class="absolute right-1 bottom-1 rounded-full border bg-green-500 text-white size-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
