<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Button from 'primevue/button';
  import { Tippy } from 'vue-tippy';
  import { useChatStore } from '@src/store/chat';
  import Popover from 'primevue/popover';
  import { Channel } from '@pubnub/chat';
  import ThumbtackIcon from '@components/svgs/PinIcon.vue';
  import ChatPinnedMessage from '@components/Chat/ChatPinnedMessage.vue';
  import { StarIcon, BugAntIcon } from '@heroicons/vue/24/outline';
  import { useAppStore } from '@src/store/app';
  import { useToast } from 'primevue/usetoast';
  import UnMuteIcon from '@components/svgs/UnMuteIcon.vue';
  import MuteIcon from '@components/svgs/MuteIcon.vue';
  import { MyTimeInChatMessage } from '@src/types/ChatAndMessaging';
  import ChatService from '@src/services/chat-service';

  const toast = useToast();
  const chatStore = useChatStore();
  const appStore = useAppStore();
  const pins = ref<typeof Popover>();
  const emit = defineEmits(['add-member', 'open-sidebar', 'favorite', 'unfavorite']);
  const showParticipantControls = computed(() => {
    return !['modcon_2024', 'announcements'].includes(chatStore.currentChannelUrl);
  });

  const getUser = (userId: string) => {
    return chatStore.knownUsersByUuid.get(userId);
  };

  const togglePins = (e) => {
    pins.value?.toggle(e);
  };

  const removePin = (timetoken: string) => {
    ChatService.getInstance().removePin(timetoken);
  };

  const pinsAvailable = computed(() => {
    return chatStore.currentChannelUrl.startsWith('direct.') || chatStore.currentChannelUrl.startsWith('group.');
  });

  const isFavorite = computed(() => {
    return appStore.favoriteChannels.find((channel) => channel.id === currentChannel.value.id);
  });

  const sortedPins = computed((): MyTimeInChatMessage[] => {
    let data = chatStore.currentChannelPins;
    return data.sort((a, b) => {
      return Number(b.timetoken) - Number(a.timetoken);
    }) as MyTimeInChatMessage[];
  });

  const currentChannel = computed(() => {
    return chatStore.knownChannels.get(chatStore.currentChannelUrl) as Channel;
  });

  const toggleFavorite = () => {
    if (isFavorite.value) {
      emit('unfavorite', currentChannel.value.id);
      return;
    }
    emit('favorite', currentChannel.value.id);
  };

  const copyDebugInformation = () => {
    const debugInformation = {
      currentUserId: chatStore.chatUserId,
      currentlySubscribedChannels: ChatService.getInstance().getSubscribedChannels(),
      currentChannel: chatStore.currentChannelRaw?.id,
      currentChannelMeta: chatStore.currentChannelRaw?.custom ?? 'no custom metadata on this channel',
    };
    navigator.clipboard.writeText(JSON.stringify(debugInformation, null, 2));
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Debug information copied to clipboard',
      life: 3000,
    });
  };

  const handleMuteStatus = () => {
    if (chatStore.mutedChannels.has(currentChannel.value.id)) {
      chatStore.mutedChannels.delete(currentChannel.value.id);
    } else {
      chatStore.mutedChannels.add(currentChannel.value.id);
    }
    // update the muted channels in localstorage
    localStorage.setItem('chat-muted-channels', JSON.stringify(Array.from(chatStore.mutedChannels)));
  };
</script>
<template>
  <div class="flex w-full items-center border-b bg-white p-2 h-[64px] text-surface-500 dark:bg-surface-900 dark:text-surface-300 dark:border-white/10" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
    <h2 class="text-ellipsis font-semibold max-w-[350px]">
      <span class="line-clamp-1">{{ showParticipantControls ? chatStore.currentChannelName : chatStore.currentChannelRaw?.name }}</span>
      <span v-if="appStore.isDevUser" class="block select-text text-xs font-light">
        {{ chatStore.currentChannelUrl }}
      </span>
    </h2>

    <div class="ml-2 flex grow items-center space-x-2">
      <div v-if="showParticipantControls" class="flex items-center space-x-2">
        <Tippy placement="bottom">
          <template #content>
            <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">Add Member</div>
          </template>
          <Button icon="pi pi-plus" severity="secondary" icon-pos="left" size="small" label="Add" @click="emit('add-member')" />
        </Tippy>
        <Button v-if="pinsAvailable" severity="secondary" label="Pins" @click="togglePins">
          <ThumbtackIcon class="size-3.5" />
        </Button>
        <Button severity="secondary" label="debug" @click="copyDebugInformation">
          <BugAntIcon class="size-4" />
        </Button>
        <Tippy placement="bottom">
          <template #content>
            <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">{{ chatStore.mutedChannels.has(currentChannel.id) ? 'Unmute' : 'Mute' }} Channel</div>
          </template>
          <Button severity="secondary" label="debug" @click="handleMuteStatus">
            <UnMuteIcon v-if="chatStore.mutedChannels.has(currentChannel.id)" class="size-4" />
            <MuteIcon v-else class="size-4" />
          </Button>
        </Tippy>
        <Popover v-if="pinsAvailable" ref="pins">
          <div class="flex flex-col gap-4 w-[25rem]">
            <div class="flex items-center justify-between">
              <h3 class="font-semibold">Pinned Messages</h3>
            </div>
            <div class="h-72 overflow-y-auto pr-4 small-scroll">
              <ul v-if="sortedPins.length" role="list" class="divide-y divide-surface-100 dark:divide-surface-700">
                <li v-for="message in sortedPins" :key="message.timetoken">
                  <ChatPinnedMessage :user="getUser(message.userId)" :message="message" @remove-pin="removePin(message.timetoken)" />
                </li>
              </ul>
              <div v-else class="flex h-full items-center justify-center">Nothing here yet!</div>
            </div>
          </div>
        </Popover>
        <Tippy placement="bottom">
          <template #content>
            <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">Participants</div>
          </template>
          <Button icon="pi pi-users" severity="secondary" icon-pos="left" size="small" :label="`${chatStore.currentChannelParticipants.size}`" @click="emit('open-sidebar')" />
        </Tippy>
        <button v-if="pinsAvailable" @click="toggleFavorite">
          <StarIcon class="size-4" :class="[!isFavorite ? 'text-yellow-500 group-hover/favorite:fill-yellow-500' : 'text-yellow-500 fill-yellow-500 group-hover/favorite:text-white-500 group-hover/favorite:fill-transparent']" />
        </button>
      </div>
    </div>
  </div>
</template>
